<template>
  <!-- v-show="navshow" class="animated fadeInRight" -->
  <div id="about">
    <div class="about">
      <section>
        <!--  @click="linkTo(item)" -->
        <aside>
          <span v-for="item in navlist" :key="item.id" @click="linkTo(item)">{{item.text}}</span>
        </aside>
        <aside>
          <div>
            <span>夏龙通信服务体系</span>
            <span>7*24小时专业服务支持</span>
            <span>智能化监控</span>
            <span>与电信、联通、移动骨干网光纤互联</span>
            <span>通过ISO9001质量管理体系认证</span>
            <span>通过ISO27001信息安全认证</span>
          </div>
        </aside>
      </section>
    </div>
  </div>
</template>

<script>
// import logo from "../../assets/images/logo.png";
export default {
  data() {
    return {
      navlist: [
        { text: "公司简介", title: "Company", url: "/Company" },
        { text: "新闻中心", title: "News", url: "/News" },
        { text: "服务资质", title: "Service", url: "/Service" },
        { text: "合作伙伴", title: "Partners", url: "/Partners" },
        { text: "加入夏龙", title: "Join", url: "/Join" },
        { text: "联系我们", title: "Contact", url: "/Contact" },
      ],
      navshow: false,
    };
  },
  methods: {
    linkTo(item) {
      //点击跳转
      this.$router.push("/about" + item.url);
      localStorage.setItem("news_url", "Item");
      this.$eventbus.$emit("news_url", "Item");
      if (item.title != undefined) {
        this.$eventbus.$emit("title", item.title);
      }
      this.$eventbus.$emit("nav_show", false);
      document.querySelectorAll("#menu_nav li").forEach((el) => {
        el.style.borderBottom = "0";
      });
      document.querySelector("#menu_nav li:nth-of-type(7)").style.borderBottom =
        "1px solid white";
    },
  },
  mounted() {
    this.navshow = false;
    setTimeout(() => {
      this.navshow = true;
    }, 500);
  },
};
</script>
<style scoped lang="less">
@import "../../../assets/css/main.less";
#about {
  width: 100%;
  height: 100%;
  //   background: blanchedalmond;
  .column;
  justify-content: center;
  .about {
    width: 100%;
    height: 95%;
    // background: goldenrod;
    color: white;
    // color: black;
    .column;
    justify-content: center;
    section:nth-of-type(1) {
      width: 100%;
      height: 90%;
      //   background: gray;
      padding-left: 0.625rem;
      .row;
      justify-content: space-around;
      aside:nth-of-type(1) {
        width: 16.2%;
        height: 100%;
        // background: hotpink;
        border-right: 0.0625rem solid #ccc;
        .column;
        align-items: flex-start;
        justify-content: space-around;
        span {
          width: 45%;
          height: 2.5rem;
          transition: all ease 500ms;
          .row;
          justify-content: flex-start;
          padding-left: 1rem;
        }
        span:hover {
          width: 90%;
          background: bisque;
          color: black;
          border-radius: 3px 0 3px 0;
          // font-weight: 700;
        }
      }
      aside:nth-of-type(2) {
        width: 80%;
        height: 100%;
        // background: bisque;
        .row;
        justify-content: flex-start;
        div {
          width: 20rem;
          height: 100%;
          //background: darkkhaki;
          .column;
          align-items: flex-start;
          justify-content: space-around;
          margin-right: 0.625rem;
        }
      }
    }
  }
}
</style>